import React, { forwardRef } from "react";
import clsx from "clsx";

import type {
	PolymorphicComponentPropsWithRef,
	PolymorphicRef,
} from "../../types/polymorphic-component";

export type PanelProps<C extends React.ElementType> =
	PolymorphicComponentPropsWithRef<C>;

type PanelComponent = <C extends React.ElementType = "div">(
	props: PanelProps<C>,
) => React.ReactNode;

export const Panel: PanelComponent = forwardRef(
	<C extends React.ElementType = "div">(
		{ as, children, className, ...props }: PanelProps<C>,
		ref?: PolymorphicRef<C>,
	) => {
		const Component = as ?? "div";

		return (
			<Component
				className={clsx(
					"shadow-card-lg rounded-xl bg-white px-6 py-7 text-center md:px-9 md:py-9",
					className,
				)}
				ref={ref}
				{...props}
			>
				{children}
			</Component>
		);
	},
);
