import React, { createContext, useContext } from "react";
import { ModalState, useModalState } from "@jasperlabs/jux-next";

import AccountDetailsModal from "./AccountDetailsModal";

const AccountDetailsModalContext = createContext<ModalState | null>(null);

export const useAccountDetailsModal = () => {
	const context = useContext(AccountDetailsModalContext);

	if (!context) {
		throw new Error(
			"useAccountDetailsModal should always be used within a AccountDetailsModalProvider",
		);
	}

	return context;
};

export const AccountDetailsModalProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const modalState = useModalState();

	return (
		<AccountDetailsModalContext.Provider value={modalState}>
			{children}
			<AccountDetailsModal
				modalState={modalState}
				renderDisclosure={() => null}
			/>
		</AccountDetailsModalContext.Provider>
	);
};
