import React, { Fragment } from "react";
import PropTypes from "prop-types";

const LoginHeader = ({ heading, blurb }) => {
	if (blurb) {
		return (
			<Fragment>
				<h1 className="text-heading-sm mb-6 text-center text-white">
					{heading}
				</h1>
				<p className="text-body-default max-w=[380px] mx-auto mb-6 text-center text-white md:mb-7">
					{blurb}
				</p>
			</Fragment>
		);
	}

	return (
		<h1 className="text-heading-default mb-6 text-center text-white md:mb-9">
			{heading}
		</h1>
	);
};

LoginHeader.defaultProps = {
	blurb: null,
};

LoginHeader.propTypes = {
	heading: PropTypes.string.isRequired,
	blurb: PropTypes.string,
};

export default LoginHeader;
